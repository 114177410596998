{
  "name": "@tremaze/source",
  "version": "25.8.4",
  "license": "MIT",
  "scripts": {
    "ng": "nx",
    "postinstall": "node ./decorate-angular-cli.js",
    "nx": "nx",
    "start": "nx serve",
    "build": "nx build",
    "test": "nx test",
    "lint": "nx workspace-lint && ng lint",
    "build fuma dev": "nx build fuma-event-registration --base-href /tremonsoft/tremaze_dev/",
    "build fuma prod": "nx build fuma-event-registration --base-href /tremonsoft/tremaze/ --configuration production",
    "e2e": "ng e2e",
    "affected:apps": "nx affected:apps",
    "affected:libs": "nx affected:libs",
    "affected:build": "nx affected:build",
    "affected:e2e": "nx affected:e2e",
    "affected:test": "nx affected:test",
    "affected:lint": "nx affected:lint",
    "affected:dep-graph": "nx affected:dep-graph",
    "affected": "nx affected",
    "format": "nx format:write",
    "format:write": "nx format:write",
    "format:check": "nx format:check",
    "update": "nx migrate latest",
    "dep-graph": "nx dep-graph",
    "help": "nx help",
    "workspace-generator": "nx workspace-generator",
    "dev:ssr": "ng run tremaze-client-website:serve-ssr",
    "serve:ssr": "node dist/tremaze-client-website/server/main.js",
    "serve-tagea-cc": "nx run tagea-control-center:serve --proxy-config=\"apps/tagea-control-center/proxy.conf.json\" --no-hmr",
    "serve-tagea-cc-hmr": "nx run tagea-control-center:serve --proxy-config=\"apps/tagea-control-center/proxy.conf.json\"",
    "serve-tagea-cc-proxy-local": "nx run tagea-control-center:serve --proxy-config=\"apps/tagea-control-center/proxy.conf.local.json\"",
    "build:ssr": "ng build --configuration production && ng run tremaze-client-website:server:production",
    "prerender": "ng run tremaze-client-website:prerender",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org tremaze-ug-haftungsbeschrankt --project tagea-control-center-angular ./dist/apps/tagea-control-center && sentry-cli sourcemaps upload --org tremaze-ug-haftungsbeschrankt --project tagea-control-center-angular ./dist/apps/tagea-control-center"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.2.1",
    "@angular/cdk": "19.2.2",
    "@angular/common": "19.2.1",
    "@angular/compiler": "19.2.1",
    "@angular/core": "19.2.1",
    "@angular/forms": "19.2.1",
    "@angular/material": "19.2.2",
    "@angular/platform-browser": "19.2.1",
    "@angular/platform-browser-dynamic": "19.2.1",
    "@angular/platform-server": "19.2.1",
    "@angular/router": "19.2.1",
    "@angular/service-worker": "19.2.1",
    "@azure/msal-angular": "^4.0.5",
    "@azure/msal-browser": "^4.5.1",
    "@ffmpeg/ffmpeg": "^0.12.10",
    "@ffmpeg/util": "^0.12.1",
    "@formkit/auto-animate": "^0.8.0",
    "@fullcalendar/angular": "^6.1.16",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/resource": "^6.1.15",
    "@fullcalendar/resource-timegrid": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@jitsi/react-sdk": "^1.4.0",
    "@microsoft/microsoft-graph-client": "^3.0.7",
    "@ngneat/forms-manager": "^2.5.1",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngrx/component-store": "19.0.1",
    "@ngrx/effects": "19.0.1",
    "@ngrx/entity": "19.0.1",
    "@ngrx/operators": "19.0.1",
    "@ngrx/router-store": "19.0.1",
    "@ngrx/store": "19.0.1",
    "@ngxmc/color-picker": "^19.3.1",
    "@sentry/angular": "^9.4.0",
    "@sentry/cli": "^2.42.2",
    "@sentry/integrations": "^7.114.0",
    "@sentry/nextjs": "^8.48.0",
    "@splidejs/splide": "^4.1.4",
    "@stomp/rx-stomp": "^2.0.0",
    "@stomp/stompjs": "^7.0.0",
    "@storybook/react": "8.6.4",
    "@syncfusion/ej2-angular-pdfviewer": "^28.2.9",
    "@tinymce/tinymce-angular": "8.0.1",
    "@tremonsoft/datetime-picker": "^19.3.2",
    "@types/heap": "^0.2.34",
    "@types/js-cookie": "^3.0.5",
    "angular-gridster2": "^19.0.0",
    "angular-oauth2-oidc": "^17.0.2",
    "heap": "^0.2.7",
    "js-cookie": "^3.0.5",
    "next": "15.2.1",
    "ngx-cookie": "^6.0.1",
    "ngx-image-cropper": "^9.1.2",
    "ngx-observable-input": "^3.0.3",
    "ngx-ui-tour-core": "^14.0.0",
    "ngx-ui-tour-md-menu": "^14.0.1",
    "react": "19.0.0",
    "react-audio-visualize": "^1.1.3",
    "react-dom": "19.0.0",
    "react-hook-form": "^7.47.0",
    "react-select": "^5.8.0",
    "react-textarea-autosize": "^8.5.3",
    "react-toastify": "^9.1.3",
    "react-tooltip": "^5.25.0",
    "reflect-metadata": "^0.2.2",
    "rxjs": "7.8.2",
    "server-only": "^0.0.1",
    "swiper": "^11.0.5",
    "tinymce": "6-latest",
    "tslib": "^2.8.1",
    "uuid": "^9.0.1",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.2.1",
    "@angular-devkit/core": "19.2.1",
    "@angular-devkit/schematics": "19.2.1",
    "@angular-eslint/eslint-plugin": "19.2.0",
    "@angular-eslint/eslint-plugin-template": "19.2.0",
    "@angular-eslint/template-parser": "19.2.0",
    "@angular/cli": "~19.1.0",
    "@angular/compiler-cli": "19.2.1",
    "@angular/language-service": "19.2.1",
    "@chromatic-com/storybook": "^3.2.5",
    "@eslint/eslintrc": "^2.1.1",
    "@eslint/js": "~8.57.0",
    "@microsoft/microsoft-graph-types": "^2.40.0",
    "@ngrx/schematics": "19.0.1",
    "@ngrx/store-devtools": "19.0.1",
    "@nrwl/tao": "19.8.4",
    "@nx/angular": "20.4.6",
    "@nx/cypress": "20.4.6",
    "@nx/devkit": "20.4.6",
    "@nx/eslint": "20.4.6",
    "@nx/eslint-plugin": "20.4.6",
    "@nx/jest": "20.4.6",
    "@nx/js": "20.4.6",
    "@nx/next": "20.4.6",
    "@nx/playwright": "20.4.6",
    "@nx/storybook": "20.4.6",
    "@nx/web": "20.4.6",
    "@nx/webpack": "^20.5.0",
    "@nx/workspace": "20.4.6",
    "@playwright/test": "^1.50.1",
    "@schematics/angular": "19.2.1",
    "@sentry/esbuild-plugin": "^3.2.2",
    "@sentry/webpack-plugin": "^3.2.2",
    "@storybook/addon-essentials": "8.6.4",
    "@storybook/addon-interactions": "8.6.4",
    "@storybook/addon-themes": "8.6.4",
    "@storybook/angular": "8.6.4",
    "@storybook/core-server": "8.6.4",
    "@storybook/jest": "^0.2.3",
    "@storybook/test": "^8.6.4",
    "@storybook/test-runner": "0.22.0",
    "@storybook/testing-library": "^0.2.2",
    "@swc-node/register": "1.10.9",
    "@swc/core": "1.11.7",
    "@swc/helpers": "0.5.15",
    "@testing-library/react": "16.2.0",
    "@txtextcontrol/tx-ng-ds-document-editor": "^3.5.0",
    "@txtextcontrol/tx-ng-ds-document-viewer": "^3.5.0",
    "@types/ffmpeg": "^1.0.7",
    "@types/jasmine": "~5.1.7",
    "@types/jest": "29.5.14",
    "@types/node": "22.13.9",
    "@types/react": "19.0.10",
    "@types/react-dom": "19.0.4",
    "@types/uuid": "^9.0.4",
    "@typescript-eslint/utils": "^8.26.0",
    "angular-eslint": "^19.0.2",
    "autoprefixer": "^10.4.20",
    "babel-jest": "^29.7.0",
    "cypress": "13.17.0",
    "eslint": "9.21.0",
    "eslint-config-next": "15.2.1",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-cypress": "4.1.0",
    "eslint-plugin-import": "2.31.0",
    "eslint-plugin-jsx-a11y": "6.10.2",
    "eslint-plugin-playwright": "^2.2.0",
    "eslint-plugin-react": "7.37.4",
    "eslint-plugin-react-hooks": "5.2.0",
    "html-webpack-plugin": "^5.6.3",
    "jasmine-core": "~5.6.0",
    "jasmine-spec-reporter": "~7.0.0",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-environment-node": "^29.7.0",
    "jest-preset-angular": "14.5.3",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "license-checker": "^25.0.1",
    "ng-packagr": "19.2.0",
    "nx": "20.4.6",
    "prettier": "^3.5.3",
    "storybook": "^8.6.4",
    "ts-jest": "^29.2.6",
    "ts-node": "10.9.2",
    "tsconfig-paths-webpack-plugin": "^4.1.0",
    "typescript": "5.8.2",
    "typescript-eslint": "^8.19.0"
  },
  "peerDependencies": {
    "@stomp/stompjs": "^7.0.0"
  },
  "optionalDependencies": {
    "@nx/nx-darwin-arm64": "^20.4.6",
    "@nx/nx-darwin-x64": "^20.4.6",
    "@nx/nx-linux-x64-gnu": "^20.4.6",
    "@nx/nx-win32-x64-msvc": "^20.4.6"
  }
}
